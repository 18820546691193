<!-- 可选成员组件 -->
<template>
  <div>
    <div class="row" style="margin-top: 10px">
      <div class="pull-left dragBox">
        <span class="font_style">
          <!-- 可选成员 -->
          {{ $t("vue_label_commonobjects_view_members") }}
        </span>
        <div class="shuttle">
          <div class="shuttle_sea">
            <!-- 请选择 -->
            <el-select
              v-model="curRole"
              @change="handleCurRole"
              :placeholder="$t('label_tabpage_pleaseSelectz')"
              filterable
              :disabled="targetUser"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="{ value: item.value, label: item.label }"
              ></el-option>
            </el-select>
            <div class="searchDiv">
              <!-- 搜索视图 -->
              <el-input
                :placeholder="$t('label.quickbooks.searchs')"
                class="left_inp"
                v-model="searchVal"
              ></el-input>
            </div>
          </div>
          <draggable
            class="list-group ms-column itemBox shu_left"
            v-model="originFields"
            group="tasks"
            animation="300"
            @end="onEndLeft"
          >
            <span
              class="dragItem"
              v-for="(element, index) in originFields"
              :key="index"
              @click="allLeftSelect(element, index)"
              :class="[element.isselect ? 'Selectes' : '']"
              :id="element.id"
              >{{ element.text }}
            </span>
          </draggable>
        </div>
      </div>
      <el-button-group class="buttonGroup">
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('vue_label_normal_cancel')"
          placement="top-start"
        >
          <el-button
            type="primary"
            class="left"
            @click="allRight && allRight.length > 0 ? allRightClick() : ''"
          >
            <svg
              class="icon leftBlack"
              aria-hidden="true"
              style="margin-left: 3px"
            >
              <use href="#icon-leftBlack-copy"></use>
            </svg>
          </el-button>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('vue_label_normal_select')"
          placement="top-start"
        >
          <el-button
            type="primary"
            @click="allLeft && allLeft.length > 0 ? allLeftClick() : ''"
            style="margin-right: 0px"
            class="rights"
          >
            <svg
              class="icon rightBlack"
              aria-hidden="true"
              style="margin-left: 3px"
            >
              <use href="#icon-rightBlack-copy"></use>
            </svg>
          </el-button>
        </el-tooltip>
      </el-button-group>
      <div class="pull-left dragBox" id="background">
        <span class="font_style">
          <!-- 已选成员 -->
          {{ $t("vue_label_email_selected_members") }}
        </span>
        <draggable
          class="list-group ms-column itemBox shu_right"
          v-model="curGroupList"
          group="tasks"
          sort="true"
          draggable="false"
          @end="onEndRight"
        >
          <p
            class="dragItem pad_s"
            v-for="(element, index) in curGroupList"
            :key="index"
            @click="allRightSelect(element, index)"
            :class="[element.isselect ? 'Selectes' : '']"
            :id="element.id"
          >
            <span>
              {{ element.text }}
            </span>
          </p>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { SearchGroup, GetSearchGroup } from "./api";

export default {
  userArr: [],
  name: "app",
  components: {
    draggable,
  },
  props: {
    // 是否启动公用小组
    isGroup: {
      type: Boolean,
      default: true,
    },
    selectedGroupList: {
      type: Array,
    },
    targetUser: {
      type: Boolean,
      default: false,
    },
    viewcell: {
      type: String,
      default: "",
    },
  },
  data() {
    let options = [
      {
        value: "User",
        label: this.$i18n.t("label.document.history.user"), //用户
      },
      {
        value: "Role",
        label: this.$i18n.t("label_tabpage_rolez"), //角色
      },
      {
        value: "RoleAndSub",
        label: this.$i18n.t("label.roleandunderling"), //角色及下属
      },
      {
        value: "area",
        label: this.$i18n.t("label.area"), //区域
      },
      {
        value: "areaAndSub",
        label: this.$i18n.t("label.areaAndSub"), //区域及下级区域
      },
      {
        value: "partnerUser",
        label: this.$i18n.t("label.partneruser"), //合作伙伴用户
      },
      {
        value: "partnerRole",
        label: this.$i18n.t("label.roleofpartner"), //合作伙伴角色
      },
    ];
    let group = {
      value: "Group",
      label: this.$i18n.t("label.publicgroup"), //公用小组
    };
    // 处理公用小组
    if (this.isGroup) {
      options.push(group);
    }
    return {
      searchVal: "",
      options,
      curRole: this.$i18n.t("label.document.history.user"), //"用户",
      curRoleId: "User",
      originFields: [],
      curGroupList: [],
      newArr: [],
      shiftKey: null, //监听是否按下shift
      origin: -1, //记录起点
      allLeft: [], //左边穿梭框存放shift选中数据
      allRight: [], //右边穿梭框存放shift选中数据
    };
  },
  mounted() {
    this.searchGroup();
    //监听是否按下shift事件，如果按下shift设置shiftKey为true
    window.addEventListener('keydown', this.keydownFn)
    //监听是否松开shift事件，如果按下shift设置shiftKey为false
    window.addEventListener('keyup', this.keyupFn)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keydownFn)
    window.removeEventListener('keyup', this.keyupFn)
  },
  methods: {
    //监听是否按下shift事件，如果按下shift设置shiftKey为true
    keydownFn(e) {
      if (e.keyCode === 16 && e.shiftKey) {
        this.shiftKey = true;
      }
    },
    //监听是否松开shift事件，如果按下shift设置shiftKey为false
    keyupFn() {
      this.shiftKey = false
    },
    //删除右侧内容
    deleGroup(val) {
      this.curGroupList = this.curGroupList.filter(
        (obj) => obj.text !== val.text
      );
      this.curRoleId = this.originFields[0].type;
      this.options.forEach((item, index) => {
        if (item.value === val.type) {
          this.curRole = item.label;
          this.curRoleId = val.type;
          this.searchGroup();
        }
      });
    },
    // 搜索用户组
    searchGroup() {
      const params = {
        searchKeyWord: this.searchVal,
        type: this.curRoleId,
      };
      if (this.viewcell == "creatededit") {
        SearchGroup(params).then((res) => {
          res.data.forEach((item, index) => {
            item.index = index;
            item.isselect = false;
          });
          this.originFields = res.data;
          this.newArr = res.data;
          this.originFieldss(res.data);
          this.$emit("getSelectData", res.data);
        });
      } else {
        GetSearchGroup(params).then((res) => {
          res.data.forEach((item, index) => {
            item.index = index;
            item.isselect = false;
          });
          this.originFields = res.data;
          this.newArr = res.data;
          this.originFieldss(res.data);
          this.$emit("getSelectData", res.data);
        });
      }
    },
    //搜索用户组
    handleCurRole(params) {
      const { value, label } = params;
      this.curRole = label;
      this.curRoleId = value;
      this.searchGroup();
    },
    cls(val, index) {
      this.originFields.splice(index, 1);
      this.curGroupList.push(val);
      this.allLeft.forEach((item, index) => {
        if (item.id === val.id) {
          this.allLeft.splice(index, 1);
        }
      });
    },
    clrears() {
      this.curGroupList = [];
      this.searchVal = "";
      this.searchGroup();
    },
    //左边穿梭框点击事件
    allLeftSelect(item, index) {
      const data = this.originFields; //左边穿梭框数据
      const origin = this.origin; //起点标识
      const enIdx = index; //选中数据的index
      if (this.shiftKey) {
        //this.shiftKey为true时
        const sum = Math.abs(origin - enIdx) + 1; //记录终点
        const min = Math.min(origin, enIdx); //记录起点
        let i = 0;

        while (i < sum) {
          const index = min + i;
          this.originFields[index].isselect = true; //接口返回数据时添加isselect字段默认为false，如果选中则为true用以添加样式
          i++;
          this.allLeft.push(this.originFields[index]); //将shift选中的数据存放到数组中
        }
        this.unique(this.allLeft); //将左边存放shift数据的数组进行去重
      } else {
        //this.shiftKey为false时
        this.origin = index;
        if (item.isselect) {
          //判断是否当前选中的字段是否为已选中状态
          item.isselect = false; //如果当前选中的字段是true则改为false
          this.allLeft.forEach((items, index) => {
            //将当前选中的字段是true改为false时删除this.allLeft中的这条数据
            if (items.id === item.id) {
              this.allLeft.splice(index, 1);
            }
          });
        } else {
          this.allLeft.push(item); //如果不为true则添加到this.allLeft中
          item.isselect = true;
        }
      }
    },
    allLeftClick() {
      //点击向右按钮
      this.allLeft.forEach((item, index) => {
        item.isselect = false; //将选中状态改为false
        this.curGroupList.push(item); //点击向右的按钮将状态为选中的数据存放到右边的穿梭框中
        this.originFields.forEach((items, index) => {
          if (item.id === items.id) {
            this.originFields.splice(index, 1); //删除左边穿梭框中被选中的数据
          }
        });
      });
      this.$nextTick(() => {
        this.unique(this.curGroupList); //对右边穿梭框的数据进行去重
        this.allLeft = [];
      });
    },
    allRightSelect(item, index) {
      const data = this.curGroupList; //右边穿梭框数据
      const origin = this.origin; //起点标识
      const enIdx = index; //选中数据的index
      if (this.shiftKey) {
        //this.shiftKey为true时
        const sum = Math.abs(origin - enIdx) + 1; //记录重点
        const min = Math.min(origin, enIdx); //记录起点
        let i = 0;

        while (i < sum) {
          const index = min + i;
          this.curGroupList[index].isselect = true; //接口返回数据时添加isselect字段默认为false，如果选中则为true用以添加样式
          i++;
          this.allRight.push(this.curGroupList[index]); //将shift选中的数据存放到数组中
        }
        this.$forceUpdate();
      } else {
        //this.shiftKey为false时
        this.origin = index;
        if (item.isselect) {
          item.isselect = false; //如果当前选中的字段是true则改为false
          this.allRight.forEach((items, index) => {
            //将当前选中的字段是true改为false时删除this.allLeft中的这条数据
            if (items.id === item.id) {
              this.allRight.splice(index, 1);
            }
          });
        } else {
          item.isselect = true;
          this.allRight.push(item); //如果不为true则添加到this.allRight
        }
      }
      this.$forceUpdate();
    },
    allRightClick() {
      this.unique(this.allRight); //将右边存放shift数据的数组进行去重
      let newCurGroupList = [];
      this.allRight.forEach((item) => {
        item.isselect = false; //将选中状态改为false
        if (item.type === this.curRoleId) {
          //判断当前选中数组是否是分钟的数据
          this.originFields.unshift(item); //点击向左的按钮将状态为选中的数据存放到右边的穿梭框中
        }
        //右侧选中的数据进行过滤
        this.allRight.forEach((val) => {
          newCurGroupList = this.curGroupList.filter(
            (item) => item.id !== val.id
          );
          this.curGroupList = newCurGroupList;
        });
      });
      this.$nextTick(() => {
        this.unique(this.originFields); //对左边穿梭框的数据进行去重
        this.allRight = [];
      });
    },
    //监听左边穿梭框拖拽结束事件
    onEndLeft(e) {
      //拖拽结束将拖拽的记录从左边选中的数组中删除
      this.allLeft.forEach((item, index) => {
        if (item.id === e.clone.id) {
          this.allLeft.splice(index, 1);
        }
      });
    },
    //监听右边穿梭框拖拽结束事件
    onEndRight(e) {
      this.deleGroup(e);
      //拖拽结束将拖拽的记录从右边选中的数组中删除
      this.allRight.forEach((item, index) => {
        if (item.id === e.clone.id) {
          this.allRight.splice(index, 1);
        }
      });
    },
    //数组去重
    unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            //第一个等同于第二个，splice方法删除第二个
            arr.splice(j, 1);
            j--;
          }
        }
      }
      return arr;
    },
    curGroupLists() {
      let selecteArr = [];
      this.curGroupList.forEach((val, index) => {
        selecteArr.push(val.id);
      });

      this.$emit("memberFun", selecteArr.join(","));
    },
    originFieldss(val) {
      val.forEach((item, index) => {
        this.curGroupList.forEach((items) => {
          if (item.id === items.id) {
            val.splice(index, 1);
          }
        });
      });
    },
    // 查询数据去重
    array_diff(a, b) {
      for (var i = 0; i < b.length; i++) {
        for (var j = 0; j < a.length; j++) {
          if (a[j].type === b[i].type && a[j].id === b[i].id) {
            a.splice(j, 1);
            j = j - 1;
          }
        }
      }
      return a;
    },
  },
  watch: {
    searchVal(nval, oval) {
      this.originFields = this.newArr.filter((item) => {
        return (
          item.text && item.text.toLowerCase().indexOf(nval.toLowerCase()) != -1
        );
      });
    },
    selectedGroupList: {
      handler(val, oldval) {
        this.$nextTick(() => {
          if (val && val.length !== 0) {
            this.curGroupList = val;
            this.originFields = this.array_diff(this.newArr, this.curGroupList);
          }
        });
      },
      immediate: true, //immediate表示在watch中首次绑定的时候，是否执行handler，值为true则表示在watch中声明的时候，就立即执行handler方法，值为false，则和一般使用watch一样，在数据发生变化的时候才执行handler。
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.shuttle_sea ::v-deep {
  display: flex;
  position: relative;
  height: 27px !important;
  .el-select {
    width: 65%;
    .el-input {
      ::v-deep .el-input__inner {
        padding-right: 30px;
        height: 21px !important;
        background-color: #fff !important;
      }
    }
  }
  .searchDiv {
    line-height: 27px !important;
    width: 50%;
    .iconfont {
      position: absolute;
      top: 0;
      right: 10px;
      z-index: 99;
    }
  }
}

.shuttle {
  width: 176px;
  height: 268px;
  border: 1px solid #ddd;
  position: relative;
}
.shuttle ::v-deep input {
  width: 100%;
  padding-right: 0;
  border: 0;
  background: none;
  outline: none;
  border: none;
  font-size: 12px;
}

.shu_right {
  width: 176px !important;
  height: 268px;
  border: 1px solid #ddd;
  border-radius: 3px;
  .pad_s {
    padding-left: 10px;
    display: flex;
    justify-content: space-between;
  }
}

.shu_left {
  height: 90%;
  border-top: 1px solid #ddd;
  cursor: pointer;
}
.shu_left span,
.shu_right span {
  font-size: 12px;
}

.itemBox {
  width: 100%;
  padding: 5px 0;
  overflow: auto;
}

.dragItem {
  width: 100%;
  display: inline-block;
  padding: 0 10px;
  line-height: 30px;
  cursor: pointer;
  &:hover {
    background: #ddd;
  }
}

.btns {
  width: 100%;
  margin: 10px 15px 10px 0;
  text-align: right;
}
.font_style {
  font-size: 14px;
  color: #666666;
  font-weight: bold;
}
.left_inp ::v-deep .el-input__inner {
  padding: 0;
}
::v-deep .el-input__inner {
  margin-left: 0 !important;
}

::v-deep .Selectes {
  background: #ddd;
}

::v-deep .buttonGroup {
  display: block;
  float: left;
  padding: 125px 10px;
}
::v-deep .buttonGroup {
  display: block;
  float: left;
  padding: 125px 30px;
  padding: 124px 10px;
  display: flex;
  .el-button {
    background: #fff;
  }
  .el-button:hover {
    background: #c3e6f194;
  }
  .el-button:focus {
    background-color: #006dcc !important;
    border-color: #006dcc !important;
    .leftBlack,
    .rightBlack {
      fill: #fff;
    }
  }
  .el-button {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 3px !important;
    padding: 9px 2px !important;
    border: 1px solid #e2e2e2 !important;
    .leftBlack,
    .rightBlack {
      fill: #000;
    }
  }
}
::v-deep .el-input.is-disabled .el-input__inner {
  background: none !important;
}
</style>
